.empty {
  border: 1px dashed var(--border-color);
  text-align: center;
  padding: var(--spacing-l);
}

.empty__label {
  margin-bottom: 20px;
  color: var(--light-body-color);
}
