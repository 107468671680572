.search {
  display: flex;
  align-items: stretch;
}

.search__input {
  flex: 1;
  margin-right: 15px;
}

.container {
  min-height: 200px;
  position: relative;
  margin-top: var(--spacing-l);
}

.products {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-column-gap: var(--spacing-m);
  grid-row-gap: var(--spacing-m);
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
}

.products__loading {
  opacity: 0.6;
}

.product {
  text-align: center;
  padding: 3px;
  padding-bottom: 0;
  border-radius: 3px;
  border: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background-color: transparent;
  appearance: none;
  font: inherit;
}

.product:hover {
  border-color: var(--accent-color);
}

.product__image {
  display: block;
  padding-top: 100%;
  background-size: cover;
  background-color: var(--light-bg-color);
}

.product__title {
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin: var(--spacing-s) 0;
}

.product__content {
  line-height: 1.2;
  text-align: left;
  flex: 1;
}

.product__code {
  font-size: 13px;
  margin-top: 2px;
  color: #999;
}

.empty {
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--light-body-color);
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light-bg-color);
}