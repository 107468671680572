.value {
  border: 1px solid var(--border-color);
  text-align: center;
  padding: 20px;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  position: relative;
}

.loading {
  opacity: 0.6;
}

.product {
  display: flex;
  align-items: center;
}

.product__image {
  width: 150px;
  margin-right: 20px;
  background-size: cover;
  border-radius: 4px;
  background-position: center center;
  border-radius: 1px solid var(--border-color);
  padding: 2px;
}
.product__image:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.product__info {
  flex: 1;
  text-align: left;
}

.product__title {
  margin-bottom: 3px;
  color: var(--accent-color);
}

.product__title a {
  font-size: 17px;
  text-decoration: none;
  font-weight: bold;
  color: inherit;
}

.product__title a:hover {
  text-decoration: underline;
}

.product__title svg {
  font-size: 0.8em;
  padding-left: 0.5em;
  display: inline;
}

.product__description {
  color: var(--light-body-color);
  font-size: 0.9em;
  margin-bottom: 5px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.product__code {
  font-size: 0.9em;
  color: var(--light-body-color);
  margin-bottom: 10px;
}

.reset {
  position: absolute;
  top: 20px;
  right: 20px;
  border: 0;
  background: none;
  opacity: 0.6;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
  font-size: 18px;
}

.reset:hover {
  opacity: 0.8;
}
